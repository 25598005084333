@import '~@adyen/adyen-web/dist/adyen.css';

@import 'variables';
@import 'v2-colors';
@import 'theme_vars';
@import 'legacy_globals';

.adyen-checkout__payment-method {
  /* Payment method container */
  border: none;
}
.adyen-checkout__payment-method--selected {
  /* Payment method that has been selected */
  background-color: var(--ion-background-color);
}
.adyen-checkout__payment-method__header {
  /* Payment method icon and name */
  visibility: hidden;
  height: 0;
  padding: 0;
}

.adyen-checkout__payment-method__details__content {
  position: relative;
}

.adyen-checkout__button--pay {
  /* Pay button */
  position: fixed;
  bottom: 0;
  width: calc(100% - 16px);
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--v2-color-primary);
  text-transform: uppercase;
  margin-bottom: 35px;
  font-family: $sans-font-family;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.96px;
  line-height: normal;
  font-weight: 500;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.adyen-checkout__button--pay:hover {
  background-color: var(--v2-color-primary);
  color: white;
}

.adyen-checkout__field {
  /* Form field container */
  margin-bottom: 25px;
  margin-top: 0px;
  font-family: $sans-font-family;
  font-size: 0.4375rem;
  color: $ready-gray;
}

.adyen-checkout__fieldset__title {
  padding-bottom: 0px;
}

.adyen-checkout__label {
  /* Form label container */
  position: relative;
  // background-color: var(--ion-background-color);
}

.adyen-checkout__label__text {
  /* Text element inside the form label container */
  top: -1ex;
  padding: 0px 5px;
  position: absolute;
  z-index: 1;
  background-color: var(--color-ui-white);
  left: 20px;
  color: $ready-gray;
}

.adyen-checkout__input {
  /* Input fields */
  position: relative;
}

.adyen-checkout__input--postalCode {
  width: 180px;
}

.adyen-checkout__fieldset--billingAddress > .adyen-checkout__fieldset__title {
  visibility: hidden;
  margin: 0px;
  height: 0px;
}

.adyen-checkout__card__exp-cvc {
  margin-bottom: 25px;
}

.adyen-checkout__store-details {
  margin: 0;
}

.adyen-checkout__checkbox__label {
  /* Checkbox labels */
  font-family: $sans-font-family;
  font-size: 14px;
  color: $gray-dark-text;
}

.gpay-card-info-container {
  width: 100%;
  display: grid;
  justify-content: center;
}

.adyen-checkout__applepay__button {
  width: 100%;
  height: 100%;
}
