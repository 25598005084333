// All changes made here must be reflected in color.service.ts, v2-colors.model.ts, and v2-colors-list.scss

:root {
  --v2-color-primary: unset;
  --v2-color-primary-rgb: unset;
  --v2-color-primary-tint: unset;
  --v2-color-secondary: unset;
  --v2-color-secondary-rgb: unset;
  --v2-color-secondary-tint: unset;

  --v2-color-white: #ffffff;
  --v2-color-off-white: #faf8f8;
  --v2-color-light-grey: #e7e7e7;
  --v2-color-grey: #d5d5d5;
  --v2-color-inactive-grey: #999999;
  --v2-color-medium-grey: #727272;
  --v2-color-dark-grey: #5d5d5d;
  --v2-color-darkest-grey: #3b3b3b;
  --v2-color-black: #1a1a1a;

  --v2-color-alert-orange: #e37f29;
  --v2-color-alert-orange-tint: #f7f4f0;
  --v2-color-service-blue: #014080;
  --v2-color-service-blue-dark: #062f3f;
  --v2-color-service-blue-tint: #f0f4f7;
  --v2-color-discount-green: #018049;
  --v2-color-discount-green-tint: #f0f7f4;
}
