// Common theme styles should go here

:root {
  --color-ui-dark-grey: #4a4a4a;
  --color-ui-grey: #9b9b9b;
  --color-ui-light-grey: #d5d5d5;
  --color-ui-lighter-grey: #e7e7e7;
  --color-ui-lightest-grey: #f5f5f5;
  --color-ui-red: #eb1f16;
  --color-ui-white: #ffffff;
  --color-ui-pure-black: #000000;
  --color-ui-black: #323232;
  --color-ui-pink: #f8d9dd;
  --color-ui-blue: #5699b6;
}

// Custom Colors
$ready-blue-text: #09b0ff;
$ready-blue: #1ab5ff;
$ready-light-blue-new: #edf5f9;
$ready-dark-blue-new: #062f3f;
$dark-blue: #0a396b;
$dark-blue-opacity: rgba(10, 57, 107, 0.1);
$light-blue: #ddf4ff;

$ready-dark-yellow: #ffc029;
$ready-yellow: #ffd642;
$light-yellow: #ffe6a9;
$dark-brown: #523706;
$caution-orange: #ff8e6f;
$demo-orange: #f5a623;
$alert-red: #f66;
$danger-red: #ff3434;
$light-red: #ffeaea;

$ready-green-text: #15cb8b;
$ready-green: #22dd9c;
$light-green: #caf6e6;
$error-message-background-color: #ffeaea;

// Grays
$black: #000;
$darkest-gray: #2a2a2a;
$darker-gray: #333;
$dark-gray: #6e6e6e;
$gray-dark-text: #4a4a4a;
$gray-text: #8b8b8b;
$gray: #9e9e9e;
$dark-gray-on-gray: #555555;
$light-gray: #b7b7b7;
$lighter-gray-1: #d7d7d7;
$lighter-gray-2: #efefef;
$lighter-gray-3: #e4e4e4;
$lightest-gray: #f7f7f7;
$white: #fff;

$ready-purple: #1f202e;
$ready-purple-10: lighten($ready-purple, 80%);
$ready-dark-gray: #4a4a4a;
$ready-gray: #9b9b9b;
$ready-light-gray: #d7d7d7;
$ready-mid-light-gray: #e7e7e7;
$ready-lighter-gray: #f5f5f5;

// ORDERING COLORS
$order-gray: #9b9b9b;

$main-theme-color: $ready-blue;
$main-theme-background-color: $ready-blue;

$input-gray: rgba(255, 255, 255, 0.3);

// Stray colors from v1

$energized: #ffd342 !default;

// Color for headers. `contrast` color is the text color and `base` colour is background color
$colors: (primary: $ready-blue-text);

// Breakpoints
$breakpoint-sm: 320px;
$breakpoint-md: 768px;
$breakpoint-lg: 1200px;

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin gradient($pointA, $pointB, $from, $to) {
  background: $to; /* Old browsers */
  background: -moz-linear-gradient($pointA, $from 0%, $to 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(
                  $pointA,
                  $from 0%,
                  $to 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
                  $pointB,
                  $from 0%,
                  $to 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

// App iOS Variables
// --------------------------------------------------
// iOS only Sass variables can go here

$toolbar-ios-height: 60.8px;
$navbar-ios-height: 60.8px;

// App Material Design Variables
// --------------------------------------------------
// Material Design only Sass variables can go here

$toolbar-md-height: 60.8px;
$navbar-md-height: 60.8px;

.button-md {
  text-transform: none !important;
  box-shadow: none !important;
}

.button-bold:hover,
.button-bold.button-md:hover:not(.disable-hover) {
  filter: brightness(90%);
  color: $white !important;
}

.bar-button-default-ios,
.bar-button-default-md,
.button-clear-ios,
.button-clear-md {
  color: var(--ion-color-primary, $ready-purple) !important;
}

.super-button:hover,
.super-button.button-md:hover:not(.disable-hover) {
  // background-color: darken($ready-yellow,10%) !important;
  // color: $dark-brown !important;
}

.white-ghost-button:hover,
.white-ghost-button.button-md:hover:not(.disable-hover) {
  background-color: rgba($black, 0.3) !important;
}

// Fonts
// --------------------------------------------------
$sans-font-family: 'Lato', sans-serif; // Ready UI Font
$helvetica-font-family: 'Helvetica', 'Arial', sans-serif; // iOS Native
$monospace-font-family: 'Lato', sans-serif;
$web-font-family: 'Raleway', sans-serif; // Web Use

$extrabold: 800;
$bold: 700;
$semibold: 600;
$regular: 400;

.bold {
  font-weight: $bold;
}

.themed {
  font-family: var(--font-body-name, 'Lato'), 'Lato', sans-serif;
  &.color {
    color: var(--color-light);
    &.primary {
      background-color: var(--ion-color-primary, $ready-purple);
    }
    &.primary.tint {
      background-color: var(--ion-color-light-shade, darken($ready-purple, 25%));
    }
    &.secondary {
      background-color: var(--ion-color-secondary, $ready-purple);
    }
    &.secondary.tint {
      background-color: var(--ion-color-secondary-tint, darken($ready-purple, 25%));
    }

    &.black {
      background-color: var(--color-ui-black, black);
    }
    &.white {
      background-color: var(--color-ui-white, white);
    }
    &.ui.dark-grey {
      background-color: var(--color-ui-dark-grey, $ready-dark-gray);
    }
    &.ui.grey {
      background-color: var(--color-ui-grey, $ready-gray);
    }
    &.ui.light-grey {
      background-color: var(--color-ui-light-grey, $ready-light-gray);
    }
    &.ui.lightest-grey {
      background-color: var(--color-ui-lightest-grey, $ready-lighter-gray);
    }
    &.ui.pink {
      background-color: var(--color-ui-pink);
    }
  }

  &.foreground-primary {
    color: var(--ion-color-primary) !important;
  }

  &.foreground-primary-tint {
    color: var(--ion-color-light-shade) !important;
  }

  &.foreground-secondary {
    color: var(--ion-color-secondary) !important;
  }
  &.foreground-secondary-tint {
    color: var(--ion-color-secondary-tint) !important;
  }

  &.foreground-light {
    color: var(--color-light) !important;
  }
  &.foreground-dark {
    color: var(--color-ui-dark-grey) !important;
  }

  &.foreground-light-grey {
    color: var(--color-ui-light-grey) !important;
  }
  &.foreground-grey {
    color: var(--color-ui-grey) !important;
  }
  &.foreground-white {
    color: var(--color-ui-white) !important;
  }
  &.foreground-black {
    color: var(--color-ui-black) !important;
  }

  .toolbar-md,
  .toolbar-ios {
    min-height: 61px;
  }

  @media (orientation: landscape) {
    & ion-title {
      // N.B: Chrome on android is incorrectly firing this media query, commenting this out for future investigation
      // background-size: contain;
    }
  }

  // &.header {
  //   .button-ios,
  //   .button-md,
  //   .back-button-ios,
  //   .back-button-md {
  //     height: 45px;
  //     font-size: 16px;
  //     margin: 0;
  //   }
  // }

  &.button {
    height: 1.438rem;
    text-transform: uppercase !important;
    font-size: 0.4375rem !important;
    font-family: var(--font-button-name, 'Lato'), 'Lato', sans-serif;
    &.typeA {
      border: none;
      background-color: var(--color-light, $white);
      color: var(--color-ui-dark-grey, $ready-dark-gray);
      font-weight: var(--font-button-bold-weight, bold);
      border-radius: 0 !important;
    }

    &.typeB {
      border-color: var(--color-light, $white);
      background-color: transparent;
      color: var(--color-light, $white) !important;
      font-weight: var(--font-button-bold-weight, bold);
      border: 0.03125rem solid var(--color-light, $white);
      border-radius: 0 !important;
    }

    &.typeC {
      border-color: var(--color-ui-grey, $ready-gray) !important;
      background-color: transparent;
      color: var(--color-ui-grey, $ready-gray) !important;
      font-weight: var(--font-button-bold-weight, bold);
      border: 0.03125rem solid var(--ion-color-primary, $ready-purple);
      border-radius: 0.0625rem !important;
      text-transform: capitalize !important;
    }

    &.typeD,
    &.typeE {
      background-color: var(--ion-color-primary, $ready-purple) !important;
      color: $white;
      font-weight: var(--font-button-bold-weight, bold);
      border-radius: 0 !important;
      font-weight: 600;
      letter-spacing: 0.04rem !important;

      &[disabled] {
        opacity: 0.33;
        color: $white !important;
      }
    }

    &.typeE {
      border: none !important;
      background-color: var(--ion-color-secondary) !important;
    }

    &.typeF {
      background-color: transparent;
      border: 0.03125rem solid
        var(--ion-color-primary, var(--colorPrimary, $ready-purple));
      font-weight: var(--font-button-normal-weight, normal);
      color: var(--ion-color-primary, var(--colorPrimary, $ready-purple));
      padding: 0.1563rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    &.typeG {
      background-color: transparent;
      border: 0.03125rem solid var(--color-ui-black);
      font-weight: var(--font-button-normal-weight, normal);
      color: var(--color-ui-black);
      padding: 0.1563rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    &.disable-ripple .button-effect {
      display: none;
    }

    &.typeC.selected {
      background-color: var(--ion-color-primary, $ready-purple);
      color: var(--color-ui-white, $white) !important;
      border-width: 0.05rem;
    }

    &.rounded {
      border-radius: 0.125rem !important;
    }

    &.very-rounded {
      border-radius: 1rem;
    }

    &.straight {
      border-radius: 0 !important;
    }
    &.no-border {
      border: none !important;
    }

    &:not(.typeF, .typeC):active {
      background-color: rgba(0, 0, 0, 0.1) !important;
      border-color: rgba(0, 0, 0, 0.1) !important;
    }
    &[disabled],
    &.typeA.selected {
      opacity: 0.33;
      color: var(--color-ui-dark-grey) !important;
    }
  }

  &.text {
    font-family: var(--font-body-name, ' Lato'), 'Lato', sans-serif;
    font-weight: var(--font-body-normal-weight, 'normal');
    &.largest {
      font-size: 1rem;
    }

    &.large {
      font-size: 0.875rem;
    }
    &.medium {
      font-size: 0.75rem;
    }
    &.medium-small {
      font-size: 0.625rem;
    }
    &.medium-smaller {
      font-size: 0.5rem;
    }

    &.small {
      font-size: 0.4375rem;
    }

    &.very-small {
      font-size: 0.375rem;
    }

    &.body {
      color: var(--color-ui-dark-grey);
      font-size: 0.4375rem;
    }

    &.smallest {
      font-size: 0.25rem;
    }

    &.upper {
      text-transform: uppercase !important;
    }
    &.strike {
      text-decoration: line-through;
    }
    &.wide {
      letter-spacing: 0.03125rem;
    }

    &.bold {
      font-weight: var(--font-body-bold-weight, bold);
    }

    &.underline {
      text-decoration: underline;
    }

    &.number {
      font-family: var(--font-number-name, var(--font-body-name)), 'Lato',
        sans-serif;
      font-weight: var(
        --font-number-normal-weight,
        var(--font-body-normal-weight),
        'normal'
      );
      &.bold {
        font-weight: var(--font-number-bold-weight, bold);
      }
    }

    &.header {
      font-family: var(--font-header-name, var(--font-body-name)), 'Lato',
        sans-serif;
      font-weight: var(--font-header-bold-weight, bold);
    }

    &.themed-background {
      color: var(--color-light) !important;
    }
  }

  &.input {
    &.inputA {
      input {
        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px transparent inset !important;
          -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
          transition: background-color 5000s ease-in-out 0s;
          color: var(--color-light) !important;
        }
        max-height: 1.25rem;
        border: 0.03125rem solid var(--color-light);
        border-radius: 0.125rem;
        padding: 0.3125rem;
        background: transparent;
        color: var(--color-light) !important;
        &::placeholder {
          color: var(--color-light) !important;
        }
      }
    }

    &.clear {
      input {
        border: none;
        border-radius: none;
        padding: 0;
      }
    }
  }

  &.layout {
    &.distribute-even {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    &.vertical-center {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &.icon {
    border-color: var(--ion-color-primary);
  }
}

.button-ios,
.button-md {
  height: 0.5rem;
  font-size: 0.5rem;
  margin: 0;
  z-index: 10;
}
.item-ios,
.item-md {
  font-size: 0.5rem;
}

.header-button {
  margin: 0.4rem 0.2rem;
}

.toolbar-title-ios {
  font-size: 0.625rem;
}

.item > ion-icon {
  min-height: 0;
}
.back-button-icon-md {
  font-size: 1rem;
}

.item-block {
  min-height: 0.5rem;
}

ion-header.themed {
  font-size: 0.5rem;
  .back-button-ios {
    min-height: 0px;
  }
  .back-button-md {
    min-height: 0px;
  }
  .bar-button-ios,
  .bar-button-md {
    font-size: 0.025rem;
    //min-height: 60px;
    color: var(--color-ui-white) !important;
  }

  &.plain ion-toolbar {
    .toolbar-background {
      background-color: $ready-purple !important;
    }
  }

  &.plain ion-title {
    background-image: none;
  }

  &:not(.plain) ion-toolbar {
    .toolbar-background {
      background-color: var(--ion-color-primary, $ready-purple) !important;
    }
  }

  ion-toolbar {
    .toolbar-background {
      background-color: var(--color-ui-white) !important;
    }
  }
}

ion-toolbar[color="primary"]:not(.no-theme-background-image)::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-image: var(--image-header-small);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
