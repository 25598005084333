:root {
  font-size: 32px;
}

ion-app.ios,
ion-app.md {
  font-size: 1rem;
}

body,
.scrolling-wrapper {
  scroll-behavior: smooth;
}

.select-icon {
  font-size: 0.75rem;
}

.icon-button {
  position: absolute;
  height: 48px !important;
  background: transparent;
  top: -6px;
  right: 10px;
  height: 30px;
  &:active,
  &:hover,
  &:focus {
    background: transparent;
  }
}

.back-button-ios {
  min-height: 0;
}
.bar-button-ios {
  font-size: 1rem;
  color: $white !important;
}

.alert-radio {
  font-size: 0.5rem;
  min-height: 0 !important;
}

.spinner-container {
  text-align: center;
  padding: 40px;
}

.row-border {
  border-bottom: 1px solid rgb(215, 215, 215);
}

.select-icon-border {
  border: 0.03125rem solid;
  border-radius: 50%;
  height: 0.65rem;
  width: 0.65rem;
  position: relative;

  &.large {
    height: 1.7rem;
    width: 1.7rem;

    .select-icon {
      position: absolute;
      bottom: 0.08rem;
      right: 0.3rem;
      font-size: 1.5rem;
    }
  }

  .select-icon {
    position: absolute;
    bottom: -0.1rem;
    right: 0.07rem;
  }
}

// CHECKOUT PAGE
.save-card-text {
  font-size: 18px;
  color: $gray-dark-text;
}

.bill-total-section {
  margin-bottom: 10px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  background-color: $white;
  font-size: 0.75rem;
  font-weight: $bold;
}

// ORDERING
.order-header {
  color: #4a4a4a;
  font-size: 32px;
  font-weight: $bold;
}

.order-sub-header {
  color: #4a4a4a;
  font-size: 25px;
  font-weight: $bold;
}

// TODO MIXIN?
// STRIPE
.stripe-form {
  .StripeElement {
    background-color: white;
    height: 46px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #adadae;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
}

ion-toast.success {
  .toast-message {
    background-color: $ready-light-gray;
    color: $ready-dark-gray !important;
    font-weight: bold;
    font-size: 0.5rem;
    padding: 0.5rem;
  }
}

body {
  background-color: $white !important;
}

ion-app.md,
ion-app.ios {
  background-color: transparent !important;
}

.toolbar-background {
  background-color: $white !important;
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--ion-color-primary, $ready-blue);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

// NEW LOGIN FLOW styles
.login-page {
  ion-grid {
    background: $white;
    border-radius: 20px;
  }

  .login-title {
    font-size: 28px;
  }

  .login-button {
    width: 100%;
  }
}

// Ionic Platform Overrides
.toolbar-ios {
  padding-left: 10px;
  padding-right: 10px;
}

.back-button-text-ios {
  font-family: $sans-font-family;
}
// END Ionic Platform Overrides

// Collapse-Expand Notices
ion-alert {
  &.hours-alert {
    .alert-md,
    .alert-wrapper {
      max-width: 335px;

      .alert-head,
      .alert-message {
        text-align: center;
      }
    }
  }
}

// NEW ALERT FOR ERRORS
.alertDanger {
  .alert-wrapper {
    background-color: $white;
    border-radius: 5px;

    .alert-sub-title {
      color: var(--color-ui-black);
      font-size: 16px;
      text-align: center;
    }
  }
}

.profile-photo {
  align-content: center;
  align-items: center;
  background: $white;
  background-size: cover;
  border: 2px $white solid;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
}

ion-alert {
  .alert-message {
    overflow-y: auto;
  }
}

.spinner-icon-container {
  padding-top: 20px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
  padding: 5px 16px 5px 5px;
}

.small-text {
  padding-top: 6px;
}

.left-button {
  margin-left: -4px;
  padding: 0 0 0 20px;
}

.edit-icon {
  color: $input-gray;
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
}

// END Collapse-Expand Notices

// The path for our ionicons font files, relative to the built & temporary main.css
$ionicons-font-path: '../assets/fonts' !default;

.icon-icon-home:before {
  content: '\e954';
}
.icon-icon-list:before {
  content: '\e955';
}
.icon-icon-chat:before {
  content: '\e952';
}
.icon-icon-send:before {
  content: '\e953';
}
.icon-icon-link-diagonal:before {
  content: '\e916';
}
.icon-icon-link:before {
  content: '\e94f';
}
.icon-icon-text:before {
  content: '\e950';
}
.icon-icon-paintbrush:before {
  content: '\e951';
}
.icon-icon-clock:before {
  content: '\e93c';
}
.icon-icon-fax:before {
  content: '\e93d';
}
.icon-icon-fb-messenger:before {
  content: '\e93e';
}
.icon-icon-fb:before {
  content: '\e93f';
}
.icon-icon-gplus:before {
  content: '\e940';
}
.icon-icon-grid:before {
  content: '\e941';
}
.icon-icon-hi:before {
  content: '\e942';
}
.icon-icon-history:before {
  content: '\e943';
}
.icon-icon-insta:before {
  content: '\e944';
}
.icon-icon-linkedin:before {
  content: '\e945';
}
.icon-icon-page:before {
  content: '\e946';
}
.icon-icon-person:before {
  content: '\e947';
}
.icon-icon-plus:before {
  content: '\e948';
}
.icon-icon-quotes:before {
  content: '\e949';
}
.icon-icon-sms:before {
  content: '\e94a';
}
.icon-icon-snapchat:before {
  content: '\e94b';
}
.icon-icon-stopwatch:before {
  content: '\e94c';
}
.icon-icon-trash-can:before {
  content: '\e94d';
}
.icon-icon-twitter:before {
  content: '\e94e';
}
.icon-icon-arrow-down:before {
  content: '\e90b';
}
.icon-icon-arrow-left:before {
  content: '\e915';
}
.icon-icon-arrow-right:before {
  content: '\e92a';
}
.icon-icon-arrow-up:before {
  content: '\e938';
}
.icon-arrow:before {
  content: '\e900';
}
.icon-icon-bookmark-empty:before {
  content: '\e901';
}
.icon-icon-bookmark:before {
  content: '\e902';
}
.icon-icon-calendar:before {
  content: '\e903';
}
.icon-icon-camera:before {
  content: '\e904';
}
.icon-icon-caution:before {
  content: '\e905';
}
.icon-icon-cc:before {
  content: '\e906';
}
.icon-icon-check:before {
  content: '\e907';
}
.icon-icon-chevron-left:before {
  content: '\e908';
}
.icon-icon-chevron-right:before {
  content: '\e909';
}
.icon-icon-close:before {
  content: '\e90a';
}
.icon-icon-empty:before {
  content: '\e90c';
}
.icon-icon-filled:before {
  content: '\e90d';
}
.icon-icon-filter:before {
  content: '\e90e';
}
.icon-icon-gear:before {
  content: '\e90f';
}
.icon-icon-go:before {
  content: '\e910';
}
.icon-icon-group:before {
  content: '\e911';
}
.icon-icon-hamburger-menu:before {
  content: '\e912';
}
.icon-icon-help:before {
  content: '\e913';
}
.icon-icon-key:before {
  content: '\e914';
}
.icon-icon-location-pin:before {
  content: '\e917';
}
.icon-icon-location-services:before {
  content: '\e918';
}
.icon-icon-lock:before {
  content: '\e919';
}
.icon-icon-magnify:before {
  content: '\e91a';
}
.icon-icon-mail:before {
  content: '\e91b';
}
.icon-icon-menu:before {
  content: '\e91c';
}
.icon-icon-mobile:before {
  content: '\e91d';
}
.icon-icon-more:before {
  content: '\e91e';
}
.icon-icon-new-window:before {
  content: '\e91f';
}
.icon-icon-pencil:before {
  content: '\e920';
}
.icon-icon-phone:before {
  content: '\e921';
}
.icon-icon-photo:before {
  content: '\e922';
}
.icon-icon-pointer-arrow-left:before {
  content: '\e923';
}
.icon-icon-profile-1:before {
  content: '\e924';
}
.icon-icon-profile-2:before {
  content: '\e925';
}
.icon-icon-receipt:before {
  content: '\e926';
}
.icon-icon-refresh:before {
  content: '\e927';
}
.icon-icon-reply:before {
  content: '\e928';
}
.icon-icon-reviews:before {
  content: '\e929';
}
.icon-icon-rounded-up-arrow:before {
  content: '\e92b';
}
.icon-icon-scan-card:before {
  content: '\e92c';
}
.icon-icon-scan-qrcode:before {
  content: '\e92d';
}
.icon-icon-selected:before {
  content: '\e92e';
}
.icon-icon-shop:before {
  content: '\e92f';
}
.icon-icon-split:before {
  content: '\e930';
}
.icon-icon-star-outline:before {
  content: '\e931';
}
.icon-icon-star:before {
  content: '\e932';
}
.icon-icon-stats:before {
  content: '\e933';
}
.icon-icon-table:before {
  content: '\e934';
}
.icon-icon-test:before {
  content: '\e935';
}
.icon-icon-touch-id:before {
  content: '\e936';
}
.icon-icon-unlocked:before {
  content: '\e937';
}
.icon-icon-upload:before {
  content: '\e939';
}
.icon-icon-wine:before {
  content: '\e93a';
}
.icon-icon-yelp:before {
  content: '\e93b';
}
// End Fonts

// Colours
.white {
  color: $white;
}

.gray {
  color: $gray;
}

.dark-gray {
  color: $dark-gray;
}

.black-text {
  color: $black;
}

.blue-text {
  color: var(--ion-color-primary, $ready-blue);
}

.blue-text-darker {
  color: var(--ion-color-primary, $ready-blue);
}

.deep-yellow {
  background: $ready-dark-yellow;
}

.yellow {
  background: $ready-yellow;
}

.light-yellow {
  background: $light-yellow;
}

.dark-blue {
  background: $dark-blue;
}
// End Colours

// Typography
body,
div {
  font-family: var(--font-body-name, $sans-font-family), 'Lato', sans-serif;
}

a {
  color: var(--ion-color-primary, $ready-blue);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}

.helvetica-regular-22 {
  font-family: $helvetica-font-family;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.helvetica-bold-17 {
  font-family: $helvetica-font-family;
  font-size: 17px;
  font-weight: 800;
  line-height: 20px;
}

h1,
.heading1 {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 22px;
  font-weight: $bold;
  line-height: 28px;
}

h2,
.heading2 {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 22px;
  font-weight: $semibold;
  line-height: 28px;
}

h3,
.heading3 {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 18px;
  font-weight: $bold;
  line-height: 20px;
}

h4,
.heading4 {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 17px;
  font-weight: $bold;
  line-height: 26px;
}

h5,
.heading5 {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 17px;
  font-weight: $semibold;
  line-height: 26px;
}

p,
.body {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.largebody {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.largetext {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 18px;
  font-weight: $semibold;
  line-height: 26px;
}

.normaltext {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 16px;
  font-weight: $regular;
  line-height: 22px;
}

.smalltext {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 14px;
  font-weight: $regular;
  line-height: 22px;
}

.tinytext {
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 13px;
  font-weight: $regular;
  line-height: 16px;
}

.rev-body {
  color: $white;
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 16px;
  font-weight: $semibold;
  line-height: 22px;
}

.receipt-heading1 {
  font-family: var(--font-body-name, $monospace-font-family);
  font-size: 20px;
  font-weight: $bold;
  line-height: 22px;
}

.receipt-body {
  color: $black;
  font-family: var(--font-body-name, $monospace-font-family);
  font-size: 17px;
  font-weight: $regular;
  line-height: 22px;
}

.receipt-tiny {
  font-family: var(--font-body-name, $monospace-font-family);
  font-size: 14px;
  font-weight: $regular;
  line-height: 16px;
}
// End Typography

// Buttons

.button-bold {
  color: $white;
  font-family: var(--font-body-name, $sans-font-family), 'Lato', sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  height: 46px;
  line-height: 24px;
  @include flex-center;
  border-radius: 0;
  padding: 0 5px;

  &.disabled {
    opacity: 30%;
  }
}

.ghost-button {
  background: transparent;
  color: var(--ion-color-primary-contrast, $ready-purple);
  font-family: var(--font-body-name, $sans-font-family);
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase !important;
  letter-spacing: 1.75px;
  height: 46px;
  border-radius: 0;
  line-height: 22px;
  @include flex-center;

  &:active,
  &:hover {
    background-color: $dark-blue-opacity;
  }
}

.button-link {
  @extend .ghost-button;
  border: none;
  padding: 0 20px;
}

.super-button {
  background: $ready-purple;
  color: $white;
  width: 100%;
  height: 60px;
  font-size: 18px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.25);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  &:active,
  &:hover {
    background-color: $ready-purple;
    filter: brightness(90%);
  }
}

.button-stable {
  font-family: $sans-font-family;
}

.button.disabled,
.button[disabled],
.button-grey-disabled {
  color: rgba($white, 1) !important;
  opacity: 0.3;
}

.transparent-button,
.transparent-button:hover,
.transparent-button:active {
  background: transparent;
  border: 0;
}

.transparent-button:hover {
  background-color: rgba($black, 0.1);
  border: 0;
}
// End Buttons

//Forms
ion-input .text-input {
  font-size: 18px;
  letter-spacing: 0.7px;
  line-height: 22px;
  color: var(--color-ui-black);
}

ion-input .text-input::placeholder {
  font-family: var(--font-body-name, 'Lato'), 'Lato', 'sans-serif';
  font-weight: var(--font-body-bold-weight, 'bold');
  font-style: italic;
}

// NEW READY STYLE FOR INPUTS INSTEAD OF A COMPONENT
.ready-input {
  input {
    font-family: $sans-font-family;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: -0.1px;

    &::placeholder {
      color: rgba($black, 0.6);
      font-size: 18px;
      font-style: italic;
      line-height: 20px;
      padding-top: 6px;
    }
  }
}

input.button {
  // appearance:  none;
  -webkit-appearance: none;
}

select.form-control {
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: inline;
  transition: none;
  -webkit-transition: none;
}
// End Forms

.heading-float-container {
  margin-top: 10px;
  position: absolute;
  width: 100%;
  z-index: 2;

  .progress-col {
    display: flex;
    justify-content: center;
    margin: auto;

    .dot-indicators {
      font-size: 10px;
      margin: 0 5px;

      &.dark-gray {
        color: $black;
        opacity: 0.1;
      }
    }
  }

  .next-button-col {
    display: flex;
    justify-content: flex-end;
  }

  .native-nav-center-float {
    width: 100%;
    text-align: center;
  }
}

ion-icon.back-button-icon.icon.icon-ios.back-button-icon-ios.ion-ios-arrow-back {
  font-size: 24px;
  min-width: 0;
  margin-right: 6px;
}
// End Header

// Intro
.intro-container {
  background-color: var(--ion-color-primary, $ready-blue);
  height: 100%;
  padding: 0;

  .scroll-content {
    padding-top: 50px;
  }

  .title-container {
    margin: 20px;
    width: 100%;
  }
}

.img-container {
  margin: auto;
  padding-top: 10px;
}

// Bills
.bill-container {
  border-radius: 4px;

  li {
    border: none;
    background: #f2f2f2;
    padding: 6px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.bill-multiple-pay {
  background: #ffe39b;
  padding: 10px;
}

.bill-header-text {
  text-align: center;
}

.tip-selection {
  padding: 16px 10px;
}

.tip-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tip-btn {
  flex-grow: 1;
  font-weight: 700;
  margin: 5px !important;
  width: auto !important;
  word-wrap: normal;
}

.bill-total {
  background: #f2f2f2;
  padding: 10px 16px;
  .column-left {
    width: 75%;
    text-align: right;
  }
  .column-right {
    width: 25%;
    text-align: right;
  }
}

.bill-credit {
  background: #ffe6a9;
  padding: 16px 10px;
}
// End Bills

// Rating System
ul.rating li {
  display: inline;
  border: 0px;
  background: none;
  padding: 3px 8px;
}
ul.rating li i {
  font-size: 30px;
  color: $white;
}

.yellowstars li i {
  font-size: 30px !important;
  color: $ready-dark-yellow !important;
}
// End Rating System

// Spinner
.spinner-white circle {
  fill: rgba($white, 0.9);
}

.spinner-yellow circle {
  fill: $ready-yellow;
}

.crescent-spinner-yellow circle {
  stroke: $ready-yellow;
}
// End Spinner

// Alignment & Spacing
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.no-margin {
  margin: 0;
}
.margin-col {
  margin: 0 20px;
}

.no-padding {
  padding: 0;
}

.centered {
  @include flex-center;
  align-content: center;
  text-align: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.white-overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: $white;
}

.white-overlay-text {
  height: 100%;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  text-align: center;
}

.email {
  width: 100%;
}

.modal-backdrop {
  background: transparent;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.bar {
  height: 50px;
  padding: 0;
}

// Animations
.fade-in {
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}
.fade-in-2 {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.fade.ng-hide {
  transition: 0.3s linear all;
  opacity: 0;
}

.fadeIn-setup,
.fadeOut-setup {
  -webkit-transition: 1s linear opacity;
  -moz-transition: 1s linear opacity;
  -o-transition: 1s linear opacity;
  transition: 1s linear opacity;
}

.fadeIn-setup {
  opacity: 0;
  display: block;
}

.fadeOut-setup {
  opacity: 1;
  display: block;
}

.fadeIn-setup.fadeIn-start {
  opacity: 1;
}

.fadeOut-setup.fadeOut-start {
  opacity: 0;
}

.animation-delay-03 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  12.5% {
    transform: translateX(-6px) rotateY(-5deg);
  }
  37.5% {
    transform: translateX(5px) rotateY(4deg);
  }
  62.5% {
    transform: translateX(-3px) rotateY(-2deg);
  }
  87.5% {
    transform: translateX(2px) rotateY(1deg);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateX(0);
  }
  12.5% {
    -webkit-transform: translateX(-6px) rotateY(-5deg);
  }
  37.5% {
    -webkit-transform: translateX(5px) rotateY(4deg);
  }
  62.5% {
    -webkit-transform: translateX(-3px) rotateY(-2deg);
  }
  87.5% {
    -webkit-transform: translateX(2px) rotateY(1deg);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

.shake {
  -webkit-animation: shake 400ms ease-in-out;
  animation: shake 400ms ease-in-out;
}

.ng-move {
  -webkit-transition: 1s;
  transition: 1s;

  background: gold;
}

.ng-move-active {
  background: $white;
}

.ng-enter {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.5;
}

.ng-enter-active {
  opacity: 1;
}

.animate-show {
  display: block;
  opacity: 1;
}

.animate-show.ng-hide-add {
  transition: all linear 0.3s;
}
.animate-show.ng-hide-remove {
  transition: all linear 0.3s;
}

.animate-show.ng-hide {
  opacity: 0;
}
// End Animations
