/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

/* Ready imports */
@import 'theme/variables';
@import 'theme/v2-colors';
@import 'theme/theme_vars';
@import 'theme/legacy_globals';

/* Styling for Adyen payment form */
@import 'theme/adyen';

:root {
  --bottom-safe-area-padding: calc(8px + env(safe-area-inset-bottom));
}

/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
  /* desktop scrollbars - webkit */
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--v2-color-grey);
    border-radius: 50px;
  }

  /* desktop scrollbars - firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--v2-color-grey) transparent;
  }
}
