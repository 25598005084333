// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** ready-purple **/
  --ion-color-ready-purple: #1f202e;
  --ion-color-ready-purple-rgb: 31, 32, 46;
  --ion-color-ready-purple-contrast: #ffffff;
  --ion-color-ready-purple-contrast-rgb: 255, 255, 255;
  --ion-color-ready-purple-shade: #1b1c28;
  --ion-color-ready-purple-tint: #353643;

  /** primary **/
  /** will be overwritten by location theme **/
  --ion-color-primary: #1f202e;
  --ion-color-primary-rgb: 31, 32, 46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #1b1c28;
  --ion-color-primary-tint: #353643;

  /** secondary **/
  /** will be overwritten by location theme **/
  --ion-color-secondary: #1f202e;
  --ion-color-secondary-rgb: 31, 32, 46;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1b1c28;
  --ion-color-secondary-tint: #353643;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #656565;
  --ion-color-dark-rgb: 101, 101, 101;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #595959;
  --ion-color-dark-tint: #747474;

  /** medium **/
  --ion-color-medium: #9b9b9b;
  --ion-color-medium-rgb: 155, 155, 155;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #888888;
  --ion-color-medium-tint: #a5a5a5;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** black **/
  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  /** off-white **/
  --ion-color-off-white: #f5f5f5;
  --ion-color-off-white-rgb: 245, 245, 245;
  --ion-color-off-white-contrast: #000000;
  --ion-color-off-white-contrast-rgb: 0, 0, 0;
  --ion-color-off-white-shade: #d8d8d8;
  --ion-color-off-white-tint: #f6f6f6;

  /** dark-grey **/
  --ion-color-dark-grey: #4a4a4a;
  --ion-color-dark-grey-rgb: 74, 74, 74;
  --ion-color-dark-grey-contrast: #ffffff;
  --ion-color-dark-grey-contrast-rgb: 255, 255, 255;
  --ion-color-dark-grey-shade: #414141;
  --ion-color-dark-grey-tint: #5c5c5c;

  /** cream-white **/
  --ion-color-cream-white: #faf2f4;
  --ion-color-cream-white-rgb: 250, 242, 244;
  --ion-color-cream-white-contrast: #000000;
  --ion-color-cream-white-contrast-rgb: 0, 0, 0;
  --ion-color-cream-white-shade: #dcd5d7;
  --ion-color-cream-white-tint: #fbf3f5;

  /** lightest-grey **/
  --ion-color-lightest-grey: #e7e7e7;
  --ion-color-lightest-grey-rgb: 231, 231, 231;
  --ion-color-lightest-grey-contrast: #000000;
  --ion-color-lightest-grey-contrast-rgb: 0, 0, 0;
  --ion-color-lightest-grey-shade: #cbcbcb;
  --ion-color-lightest-grey-tint: #e9e9e9;

  /** forest-green **/
  --ion-color-forest-green: #018049;
  --ion-color-forest-green-rgb: 1, 128, 73;
  --ion-color-forest-green-contrast: #ffffff;
  --ion-color-forest-green-contrast-rgb: 255, 255, 255;
  --ion-color-forest-green-shade: #017140;
  --ion-color-forest-green-tint: #1a8d5b;

  /** navy-blue **/
  --ion-color-navy-blue: #014080;
  --ion-color-navy-blue-rgb: 1, 64, 128;
  --ion-color-navy-blue-contrast: #ffffff;
  --ion-color-navy-blue-contrast-rgb: 255, 255, 255;
  --ion-color-navy-blue-shade: #013871;
  --ion-color-navy-blue-tint: #1a538d;
}

// custom named color classes
// these can be used like: color="foo"

.ion-color-ready-purple {
  --ion-color-base: var(--ion-color-ready-purple) !important;
  --ion-color-base-rgb: var(--ion-color-ready-purple-rgb) !important;
  --ion-color-contrast: var(--ion-color-ready-purple-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-ready-purple-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-ready-purple-shade) !important;
  --ion-color-tint: var(--ion-color-ready-purple-tint) !important;
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black) !important;
  --ion-color-base-rgb: var(--ion-color-black-rgb) !important;
  --ion-color-contrast: var(--ion-color-black-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-black-shade) !important;
  --ion-color-tint: var(--ion-color-black-tint) !important;
}

.ion-color-off-white {
  --ion-color-base: var(--ion-color-off-white);
  --ion-color-base-rgb: var(--ion-color-off-white-rgb);
  --ion-color-contrast: var(--ion-color-off-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-off-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-off-white-shade);
  --ion-color-tint: var(--ion-color-off-white-tint);
}

.ion-color-dark-grey {
  --ion-color-base: var(--ion-color-dark-grey);
  --ion-color-base-rgb: var(--ion-color-dark-grey-rgb);
  --ion-color-contrast: var(--ion-color-dark-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-grey-shade);
  --ion-color-tint: var(--ion-color-dark-grey-tint);
}

.ion-color-cream-white {
  --ion-color-base: var(--ion-color-cream-white);
  --ion-color-base-rgb: var(--ion-color-cream-white-rgb);
  --ion-color-contrast: var(--ion-color-cream-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cream-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-cream-white-shade);
  --ion-color-tint: var(--ion-color-cream-white-tint);
}

.ion-color-lightest-grey {
  --ion-color-base: var(--ion-color-lightest-grey);
  --ion-color-base-rgb: var(--ion-color-lightest-grey-rgb);
  --ion-color-contrast: var(--ion-color-lightest-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lightest-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-lightest-grey-shade);
  --ion-color-tint: var(--ion-color-lightest-grey-tint);
}

.ion-color-forest-green {
  --ion-color-base: var(--ion-color-forest-green);
  --ion-color-base-rgb: var(--ion-color-forest-green-rgb);
  --ion-color-contrast: var(--ion-color-forest-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-forest-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-forest-green-shade);
  --ion-color-tint: var(--ion-color-forest-green-tint);
}

.ion-color-navy-blue {
  --ion-color-base: var(--ion-color-navy-blue);
  --ion-color-base-rgb: var(--ion-color-navy-blue-rgb);
  --ion-color-contrast: var(--ion-color-navy-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-navy-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-navy-blue-shade);
  --ion-color-tint: var(--ion-color-navy-blue-tint);
}
